import request from "@utils/request";

//背景数据
export  const myBackground = ()=>{
    return request({
        url:'index/center/myBackground',
        method:'get',

    })
}
//上传背景图
export const uploadBackground = (data)=>{
    return request({
        url:'index/center/uploadBackground',
        method:'post',
        data,
    })
}
//提交审核背景图
export const sendBackground = (data) =>{
    return request({
        url:'index/center/sendBackground',
        method:'post',
        data,

    })
}
//删除背景图
export const deleteBackground = (data)=>{
    return request({
        url:'index/center/deleteBackground',
        method:'post',
        data
    })
}
