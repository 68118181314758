<template>
  <div class='container'>
    <h5>传输背景</h5>
    <div class="content">
      <div class="tip">
        <h4>
          <img src="@assets/img/profile/icon-diy.png"/>{{news.title}}
        </h4>
        <img src="@assets/img/profile/icon-vip.png" class="vip"/>
        <div v-html="news.content"></div>
      </div>
      <div class="action">
        <input type="checkbox" @click.stop="handelCheckAll"  :disabled="checkdisabled"  v-model="checkAll" id="checkAll"/>
        <label for="checkAll" >全选</label>
        <div class="submit" @click="submit">提交审核</div>
        <div class="delete" @click="delCheckBackground">删除</div>
      </div>
      <div class="fileList">
        <div class="banner-item" v-for="(item,index) in fileList" :key="index">
          <div class="banner" v-if="backgroundObj.member_vip_status == 1" :style="{backgroundImage: 'url('+uploadBg+')'}">
            <input type="checkbox" v-show="item.url"  @click="checkUpLoadBackground(item);item.checked = !item.checked" :checked="item.checked"/>
            <span v-show="false">审核中</span>
            <img :src="item.url" v-if="item.url"/>
            <input type="file" accept="image/*" @change="handleUpload($event,index)" v-else/>
          </div>
          <div class="banner" @click="jump" v-else :style="{backgroundImage: 'url('+uploadBgLock+')'}"></div>
          <div class="link" v-if="item.url">
            <img src="@assets/img/profile/icon-link.png"/>
            <input type="text" v-model.trim="item.link" placeholder="请输入链接" autocomplete="off"/>
          </div>
        </div>
      </div>
      <div class="tip2">
        <p>自定义背景设置后，会有工作人员进行审核。</p>
        <p>审核通过前，专属主页不会显示上传的背景，审核时间1-3个工作日，审核结果将会通过站内信推送。</p>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteBackground, myBackground, sendBackground, uploadBackground} from "@assets/api/uploadBackground";
import {baseUrl} from "../../../../config";

export default {
  name:'',
  components: {},

  data () {
    return {
      uploadBg:require('../../../assets/img/profile/upload-bg.png'),
      uploadBgLock:require('../../../assets/img/profile/upload-bg_lock.png'),
      isVip:false,
      tip:'<p>每个背景可独立设置跳转链接，每秒切换一次</p><p>推荐分辨率：1920px * 1080px</p>',
      fileList:[],
      backgroundList:[],//选择的图片
      backgroundObj:{},
      news:{},
      checkAll:false,//全选
      checkdisabled:true,
      uploadMaxSize:0,
    };
  },

  computed: {},

  created(){
  },
  mounted() {
    this.getuploadBackgroundData()
  },

  methods: {
    //上传
   async handleUpload(e,i){
			if(e.target.files.length==0){
				return false;
			}
      let maxsize=this.uploadMaxSize*1024*1024;
      let file=e.target.files[0];

      if (!/\.(jpg|jpeg|png||JPG|PNG|gif|GIF)$/.test(e.target.files[0].name)) {
        return this.$Message.warning('请上传正确的文件格式');
      }
      if(file.size>maxsize){
        return this.$Message.warning(`上传文件不能大于${this.uploadMaxSize}M`)
      }
      let formdata = new FormData
       formdata.append('file',file)
      let _this=this;
  		let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload= (res)=>{
        _this.$set(_this.fileList[i],'url',res.target.result)
        _this.$set(_this.fileList[i],'img_sub','')
        this.checkAll = false
        this.checkdisabled = false
        this.backgroundList.push(_this.fileList[i])
        uploadBackground(formdata).then(  res=>{
          if(res.code == 1){
            _this.fileList[i].url = res.data.img
            _this.fileList[i].img_sub = res.data.img_sub
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
			};
		},
    //获取传输背景
    getuploadBackgroundData(){
     let arr = []
      myBackground().then(res=>{
        if(res.code ==1 ){
          this.backgroundObj = res.data
          this.uploadMaxSize = res.data.file_size_max
          this.news = res.data.news
          for(let i = 0;i< 3 ;i++){
            this.fileList.push({
              id:null,
              checked:false,
              url:'',
              link:''
            })
          }
          for(let item in this.backgroundObj.list){
            this.checkdisabled = false
            this.fileList.splice(item,1,{
                  id:this.backgroundObj.list[item].id,
                  checked:false,
                  url:baseUrl.url +  this.backgroundObj.list[item].img,
                  show_img:this.backgroundObj.list[item].show_img,
                  link:this.backgroundObj.list[item].url
            })
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //全选
    handelCheckAll(){
      this.fileList.forEach((item,index)=>{
        if(!this.checkAll && item.url != ''){
          item.checked = true
        }else{
          item.checked = false
        }
      })
    },
    //删除背景
    delCheckBackground(){
      this.checkAll = false
      if(this.fileList.every(item=>item.checked == false)){
        this.$Message.error('请选择需要删除的背景图')
        return false
      }
      let delarr = []
      let data = {}
      let delState = false
      this.fileList.forEach((item,index)=>{
        if(item.id == null && item.checked == true ){
          this.fileList.splice(index,1,{
            id:null,
            checked:false,
            url:'',
            link:''
          })
          delState = true
        }
        if(item.id && item.checked == true){
          delarr.push(item)
          delState = false
          this.fileList.splice(index,1,{
            id:null,
            checked:false,
            url:'',
            link:''
          })
        }
      })
      if(delState){
        this.$Message.error('删除成功')
        return false
      }
      if(this.fileList.every(item=>item.checked == true)){
        this.checkdisabled = true
      }
      data.files_list = delarr
      deleteBackground(data).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //选择
    checkUpLoadBackground(data){
      this.$nextTick(()=>{
        if(this.fileList.every(item=>item.checked == true )){
          this.checkAll = true
        }else{
          this.checkAll = false
        }

      })
    },
    //审核
    submit(){
     let data = {
       files_list:[]
     }
     this.fileList.forEach(item=>{
       if(item.checked == true){
         data.files_list.push({
           id:item.id?item.id:null,
           img:item.img_sub,
           url:item.link,
         })
       }
     })
      sendBackground(data).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.fileList.forEach(item=>{
            item.checked = false
            this.checkAll = false
          })
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    jump(){
     this.$router.push({
       path:'/shop',
       name:'Shop',
       params:{
         index:1
       }
     })
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/uploadBackground.less';
</style>